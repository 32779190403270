import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonLabel,
} from '@ionic/react';
import React from 'react';

const Timesheet: React.FC = (props :any) => {


  function statusColor (status : any)  {
    switch (status) {
      case 'Fakturiert':
        return 'success'
        break;
      case 'Offen': 
        return 'primary'
        break;
      case 'Geschlossen' :
        return 'danger'
        break;
      default:
        return 'warning'
        break;
    }
  }

  return (
    <IonItem id={props.id} routerLink={ "/timesheets/" + props.id}>
      <IonLabel>{props.date}</IonLabel>
      <IonLabel>{props.user!== undefined ? props.user.firstname + ' ' + props.user.lastname : ''} </IonLabel>
      <IonLabel>{props.duration}</IonLabel>
        { props.status  ? <IonLabel color={statusColor(props.status.name)} >{props.status.name}</IonLabel> : '' }
    
    </IonItem>
  );
};
export default Timesheet;