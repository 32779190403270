import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonSkeletonText,
  IonList,
  withIonLifeCycle
} from '@ionic/react';
import Timesheet from './Timesheet';
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import Bexio from "../api/Bexio";


const mapStateToProps = (state :any) => {
   return state.authentication
}

class List extends React.Component<any,any> {

  private bexio : any; 

  constructor(props : any) {
      super(props);
      this.state = {
        timesheets : false,
        status : false, 
        users : false,
      }
  } 

  ionViewWillEnter() {//
     if (this.props.loggedIn) {
       this.bexio = new Bexio(this.props.jwt.access_token);
       this.loadTimesheets()
       this.loadStatus()
       this.loadUsers()
     }
  }

  loadTimesheets() {
     this.bexio.timesheets().then((response : any) => {
        this.setState({ timesheets : response });
     });   
  }

  loadStatus() {
     this.bexio.timesheetStatus().then((response : any) => {
        this.setState({ status : response });
     });   
  }

  loadUsers() {
     this.bexio.users().then((response : any) => {
        this.setState({ users : response });
     });   
  }

  render() { 
    if (!this.props.loggedIn) return <Redirect to='/auth' />
    let max = 30;
    let nb = 0;
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/timesheets"/>
            </IonButtons>
            <IonTitle>Timesheets list</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
              {  
                  !this.state.timesheets || !this.state.status || !this.state.users ? 
                  Array.from(Array(max).keys()).map((i : any, index : number) => (
                    <IonItem key={index}>
                      <IonSkeletonText animated style={{ width: '100%' }} />
                    </IonItem>
                  ))
                :          
                  this.state.timesheets.reverse().slice(0,max).map((ts: any) => (
                    (ts.status = this.state.status.find((x  : any) => x.id === ts.status_id), 
                     ts.user = this.state.users.find((x  : any) => x.id === ts.user_id),
                    <Timesheet key={ts.id} {...ts}></Timesheet> 
                    )
                  ))
              }
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
};
export default connect(mapStateToProps,null)(withIonLifeCycle(List));