import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonSelect,
  IonSelectOption,
  IonPage,
  IonTitle,
  IonLabel,
  IonToolbar,
  IonButton,
  IonList,
  IonSpinner,
  IonItem,
  IonSkeletonText,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonDatetime,
  IonModal,
  IonInput,
  IonCardTitle,
  IonCardContent,
  withIonLifeCycle
} from '@ionic/react';
import Timesheet from '../timesheets/Timesheet';
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import Project from './Project';
import Bexio from '../api/Bexio';
import moment from 'moment';
import { Document, Page } from 'react-pdf';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const mapStateToProps = (state :any) => {
   return state.authentication
}

class Detail extends React.Component<any,any> {

  bexio : any; 

  constructor(props : any) {
      super(props);
      this.state = {
        details : {},
        timesheets : false,
        contact : {},        
        client_services : false,
        lang : 'fr',
        status : false, 
        users : false,
        show_modal : false,
        start : moment(new Date().toUTCString()).format('YYYY-MM-DD'),
        end : moment(new Date().toUTCString()).format('YYYY-MM-DD'),
        loading : false,
        pdf : false,
        PDFwidth :  300,
        pageNumber : 1,
        maxPage : 1,
      }
  } 

  ionViewWillEnter() {//
    let element = document.getElementById('embed')
    const width : number = element ? element.offsetWidth : 300
    this.setState({PDFwidth : width })
    if (this.props.loggedIn) {
        this.bexio = new Bexio(this.props.jwt.access_token);
        this.loadDetails();
        this.loadTimesheets(); 
        this.loadStatus();  
        this.loadUsers();     
        this.loadClientServices();         
    }
  }

  loadDetails() {
     this.bexio.projects(this.props.match.params.id).then((response : any) => {
        this.setState({ details : response, start :moment(response.start_date).format('YYYY-MM-DD')  });
        this.loadContact();
     });   
  }

  loadTimesheets() {
     this.bexio.timesheetsByProject(this.props.match.params.id).then((response : any) => {
        this.setState({ timesheets : response });
     });   
  }

  loadContact() {
     this.bexio.contacts(this.state.details.contact_id).then((response : any) => {
        this.setState({ contact : response });
     });   
  }

  loadStatus() {
     this.bexio.timesheetStatus().then((response : any) => {
        this.setState({ status : response });
     });   
  }

  loadUsers() {
     this.bexio.users().then((response : any) => {
        this.setState({ users : response });
     });   
  }

  loadClientServices() {
    this.bexio.clientServices().then((response : any) => {
      this.setState({ client_services : response})
    });
  }

  export = () => {
      this.setState({loading : true});
      this.bexio.project_export(this.props.match.params.id, 
                                moment(this.state.start).format('YYYY-MM-DD'), 
                                moment(this.state.end).format('YYYY-MM-DD'), 
                                this.state.client_services,
                                this.state.lang).then((response : any) => {
          this.setState({loading : false});
          var blob=new Blob([response], {type: "application/pdf"});
          var url = window.URL || window.webkitURL;
          var file_url = url.createObjectURL(blob);        
          this.setState({pdf : file_url});
      }); 
  }

  render() { 
    if (!this.props.loggedIn) return <Redirect to='/auth' />

    return (
       <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/projects"/>
            </IonButtons>
            <IonTitle >
              { this.state.details.name  ||  <IonSkeletonText animated style={{ width: '30%' }} />  }
              { ' - '}  {   this.state.contact.name_1  || '' }
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonModal 
              isOpen={this.state.show_modal} 
              swipeToClose={true}
              onDidDismiss={() => this.setState({ show_modal : false})}
          >   
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                  Export PDF
              </IonTitle>
              <IonButtons slot="end">
                 <IonButton onClick={() => this.setState({ show_modal : false})}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
            <IonContent fullscreen> 
              <IonList>
                <IonItem>
                  <IonLabel>Début</IonLabel>
                  <IonDatetime value={this.state.start} pickerFormat="YYYY-MM-DD" onIonChange={e => this.setState({start : e.detail.value!})} display-timezone="Europe/Paris"></IonDatetime>
                </IonItem>
                <IonItem>
                  <IonLabel>Fin</IonLabel>
                  <IonDatetime value={this.state.end}   pickerFormat="YYYY-MM-DD" onIonChange={e => this.setState({end : e.detail.value!})} display-timezone="Europe/Paris"></IonDatetime>
                </IonItem>
                {!this.state.client_services? 
                  Array.from(Array(6).keys()).map((i : any, index : number) => (
                    <IonItem key={index}>
                      <IonSkeletonText   animated style={{ width: '100%' }} />
                    </IonItem>
                  ))
                  :
                 this.state.client_services.map((i : any, index : number) => (
                  <IonItem key={index}>
                    <IonLabel>{i.name}</IonLabel>
                    <IonInput  class="right" slot="end" value={i.default_price_per_hour} onIonChange={(e : any) => this.state.client_services[index].default_price_per_hour = e.detail.value}></IonInput>
                  </IonItem>
                 ))}

                <IonItem>
                  <IonLabel>Langue</IonLabel>
                  <IonSelect value={this.state.lang} onIonChange={(e : any) => this.setState({ lang : e.detail.value })} placeholder="Selectionner la langue" okText="Ok" cancelText="Annuler">
                    <IonSelectOption value="fr">Français</IonSelectOption>
                    <IonSelectOption value="en">Anglais</IonSelectOption>
                  </IonSelect>
                </IonItem>

                <IonItem>     
                  <IonSpinner name="crescent" hidden={!this.state.loading} />
                  {this.state.pdf ? <a href={this.state.pdf}  download>Télécharger</a> : '' }             
                  <IonButton onClick={this.export} slot="end">Afficher</IonButton>
                </IonItem>       


                  {this.state.pdf ? 
                    <div style={{ width: '100%', height: '100%' }} id="embed">                      
                          <Document file={this.state.pdf} onLoadSuccess={ (page) => this.setState({maxPage : page.numPages} )}  >
                              {
                                Array.from(
                                  new Array(this.state.maxPage),
                                  (el, index) => (
                                    <Page
                                      key={`page_${index + 1}`}
                                      pageNumber={index + 1}
                                    />
                                  ),
                                )
                              }
                          </Document>       
                    </div>   
                  : '' }
              </IonList>
                
            </IonContent>
          </IonModal>

          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>{this.state.contact.name_1 || <IonSkeletonText animated style={{ width: '30%' }} />}</IonCardSubtitle>
              <IonCardTitle>{this.state.details.name || <IonSkeletonText animated style={{ width: '30%' }} />}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
               {this.state.details.start_date + ' ' +this.state.details.pr_budget_type_amount} 
               <br />
               <IonButton onClick={() => this.setState({ show_modal : true})}>PDF</IonButton>
            </IonCardContent>
          </IonCard>      
          <IonList>
            {    
              !this.state.timesheets || !this.state.status || !this.state.users ? 
                Array.from(Array(10).keys()).map((i : any, index : number) => (
                  <IonItem key={index}>
                    <IonSkeletonText   animated style={{ width: '100%' }} />
                  </IonItem>
                ))
              :       
              this.state.timesheets.map((ts: any) => (
                  (ts.status = this.state.status.find((x  : any) => x.id === ts.status_id), 
                   ts.user = this.state.users.find((x  : any) => x.id === ts.user_id),
                  <Timesheet key={ts.id} {...ts}></Timesheet> 
                  )
              ))
            }
          </IonList>
        </IonContent>
      </IonPage>
    );
  };
};

export default connect(mapStateToProps,null)(withIonLifeCycle(Detail));


//<ion-datetime value="2019-10-01T15:43:40.394Z" display-timezone="utc"></ion-datetime>
