import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonLabel,
} from '@ionic/react';
import React from 'react';

const Project: React.FC = (props :any) => {

  function stateColor (state : any)  {
    switch (state) {
      case 'Aktiv':
        return 'success'
        break;
      case 'Archiviert': 
        return 'primary'
        break;
      default:
        return 'warning'
        break;
    }
  }

  return (
    <IonItem id={props.id} routerLink={ "/projects/" + props.id}>
      { props.contact  ? <IonLabel>{props.contact.name_1}</IonLabel> : '' }
      <IonLabel>{props.name}</IonLabel>
      <IonLabel color={stateColor(props.state.name)}>{props.state.name}</IonLabel>
      <IonLabel>{props.start_date}</IonLabel>
    </IonItem>
  );
};
export default Project;