import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonSkeletonText,
  IonCard,
  IonThumbnail,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  withIonLifeCycle
} from '@ionic/react';
import Contact from './Contact';
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import Project from '../projects/Project';
import Bexio from '../api/Bexio';
const mapStateToProps = (state :any) => {
   return state.authentication
}

class Detail extends React.Component<any,any> {

  private bexio : any; 

  constructor(props : any) {
      super(props);
      this.state = {
        details : {},
        projects : false,  
        states : false,
      }
  } 

  ionViewWillEnter() {//
    console.log(this.props, this.state)
    if (this.props.loggedIn) {
        this.bexio = new Bexio(this.props.jwt.access_token);
        this.loadDetails()
        this.loadStates();
        this.loadProjects();
    }
  }

  loadDetails() {
     this.bexio.contacts(this.props.match.params.id).then((response : any) => {
        this.setState({ details : response });
     });     
  }

  loadProjects() {
     this.bexio.projectsByContact(this.props.match.params.id).then((response : any) => {
        this.setState({ projects : response });
     });  
  }

  loadStates() {
     this.bexio.projectStates().then((response : any) => {
        this.setState({ states : response });
    });     
  }

  render() { 
    if (!this.props.loggedIn) return <Redirect to='/auth' />

    return (
       <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/projects"/>
            </IonButtons>
            <IonTitle >{this.state.details.name_1 || <IonSkeletonText animated style={{ width: '30%' }} /> }</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonCard>            
            { this.state.details.profile_image ? <img src={ "data:image/jpeg;base64,"+this.state.details.profile_image} /> : 
              <IonThumbnail>
                <IonSkeletonText animated />
              </IonThumbnail> 
            }
            <IonCardHeader>
              <IonCardSubtitle>{this.state.details.name_2}</IonCardSubtitle>
              <IonCardTitle>{this.state.details.name_1 || <IonSkeletonText animated style={{ width: '30%' }} />}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
               {this.state.details.address} {this.state.details.postcode} {this.state.details.city} 
            </IonCardContent>
          </IonCard>      
          <IonList>
              {       
                !this.state.projects || !this.state.states ? 
                Array.from(Array(5).keys()).map((i : any,index : number) => (
                  <IonItem key={index}>
                    <IonSkeletonText animated style={{ width: '100%' }} />
                  </IonItem>
                ))
                :      
                this.state.projects.map((pjs: any) => (                  
                   (pjs.state = this.state.states.find((x  : any) => x.id === pjs.pr_state_id)), <Project  key={pjs.id} {...pjs}></Project>
                ))
              }
          </IonList>
        </IonContent>
      </IonPage>
    );
  };
};

export default connect(mapStateToProps,null)(withIonLifeCycle(Detail));