import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,  
  IonItem,
  IonSkeletonText,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  withIonLifeCycle
} from '@ionic/react';
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import Bexio from "../api/Bexio";

const mapStateToProps = (state :any) => {
   return state.authentication
}

class Detail extends React.Component<any,any> {

  private bexio : any; 

  constructor(props : any) {
      super(props);
      this.state = {
        details : {},
        user : false,
        status : false,
        project : false,
      }
  } 

  ionViewWillEnter() {//
    console.log(this.props, this.state)
    if (this.props.loggedIn) {
        this.bexio = new Bexio(this.props.jwt.access_token);
        this.loadDetails()
    }
  }

  loadDetails() {
    this.bexio.timesheets(this.props.match.params.id).then((response : any) => {
        this.setState({ details : response });
        this.loadStatus()
        this.loadUser()
        this.loadProject()
    });   
  }

  loadStatus() {
     this.bexio.timesheetStatus(this.state.details.status_id).then((response : any) => {
        this.setState({ status : response });
     });   
  }

  loadProject() {
     this.bexio.projects(this.state.details.pr_project_id).then((response : any) => {
        this.setState({ project : response });
     });   
  }

  loadUser() {
     this.bexio.users(this.state.details.user_id).then((response : any) => {
        this.setState({ user : response });
     });   
  }

  render() { 
    if (!this.props.loggedIn) return <Redirect to='/auth' />

    return (
       <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/timesheets"/>
            </IonButtons>
            <IonTitle > {this.state.details.date|| <IonSkeletonText animated style={{ width: '30%' }} /> } 
                        { ' - '} 
                        {this.state.project.name || ''} 
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>
                 { this.state.user ? this.state.user.firstname + ' ' + this.state.user.lastname  :  <IonSkeletonText animated style={{ width: '30%' }} /> }
              </IonCardSubtitle>
              <IonCardTitle>{this.state.details.duration|| <IonSkeletonText animated style={{ width: '30%' }} /> } </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                { this.state.details.date ? 
                    <div className="content" dangerouslySetInnerHTML={{__html: this.state.details.text }}></div> 
                  :  
                    <IonSkeletonText animated style={{ width: '100%' }} /> 
                } 
            </IonCardContent>
          </IonCard>      
        </IonContent>
      </IonPage>
    );
  };
};

export default connect(mapStateToProps,null)(withIonLifeCycle(Detail));