import Menu from './components/Menu';

import TimesheetList from './timesheets/List';
import TimesheetDetail from './timesheets/Detail';

import ContactList from './contacts/List';
import ContactDetail from './contacts/Detail';

import ProjectList from './projects/List';
import ProjectDetail from './projects/Detail';


import Auth from './auth/Login';
import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import appStore from "./store/appStore";
import {Provider} from "react-redux";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';


const App: React.FC = () => {

  return (
    <IonApp>
      <Provider store={appStore}>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
              <Route path="/auth" component={Auth}   />

              <Route path="/timesheets/:id" component={TimesheetDetail}  />
              <Route path="/timesheets" component={TimesheetList} exact /> 

              <Route path="/contacts/:id" component={ContactDetail}  />
              <Route path="/contacts" component={ContactList} exact />

              <Route path="/projects/:id" component={ProjectDetail}  />
              <Route path="/projects" component={ProjectList} exact />      

            <Redirect from="/" to="/auth" exact />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
      </Provider>
    </IonApp>
  );
};

export default App;


