import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonLabel,
} from '@ionic/react';
import React from 'react';

const Contact: React.FC = (props :any) => {

  return (
    <IonItem id={props.id} routerLink={ "/contacts/" + props.id} >
      <IonLabel>{props.name_1} {props.name_2}</IonLabel>
      <IonLabel>{props.mail}</IonLabel>
    </IonItem>
  );
};
export default Contact;