import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonButton,
} from '@ionic/react';

import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { useLocation } from 'react-router-dom';
import {stopwatchSharp,stopwatchOutline, personCircleOutline, personCircleSharp, peopleOutline, peopleSharp, planetSharp, planetOutline,bookmarkSharp, bookmarkOutline } from 'ionicons/icons';
import './Menu.css';
import { connect } from 'react-redux';
import { logoutSuccess} from "../auth/Login.slice";

const mapDispatchToProps = {
   logoutSuccess,
};

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}


const mapStateToProps = (state :any) => {
   return state.authentication
}


const appPages: AppPage[] = [
  { 
    title: 'Auth',
    url: '/auth',
    iosIcon: personCircleOutline,
    mdIcon: personCircleSharp
  },
  {
    title: 'Contacts',
    url: '/contacts',
    iosIcon: peopleOutline, 
    mdIcon: peopleSharp,
  },
  {
    title: 'Projects',
    url: '/projects',
    iosIcon: planetOutline,
    mdIcon: planetSharp,
  },
  /*{
    title: 'Timesheets',
    url: '/timesheets',    
    iosIcon: stopwatchOutline,
    mdIcon: stopwatchSharp
  },*/
];

const Menu: React.FC = (props : any) => {
  const location = useLocation();
  return (
      <IonMenu contentId="main" type="overlay" side="end" swipeGesture={true}>
        <IonContent>
          <IonList id="inbox-list">
            <IonListHeader> { props.loggedIn ? props.user.firstname + ' ' + props.user.lastname  : 'Invité' }    
                    { props.loggedIn ? <IonButton onClick={props.logoutSuccess} routerLink='/auth'>Logout  </IonButton>   :  ''}    
            </IonListHeader>
            <IonNote>{props.user.email}</IonNote>
            
            {
              appPages.map((appPage, index) => {
                if ((props.loggedIn && index > 0) || (!props.loggedIn && index == 0 ))
                  return (
                    <IonMenuToggle key={index} autoHide={false}>
                      <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                        <IonIcon slot="start" icon={appPage.iosIcon} />
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  );
              })     
            }
          </IonList>
        </IonContent>
      </IonMenu>
  );
};

export default connect(mapStateToProps,mapDispatchToProps)(Menu);
