import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonSkeletonText,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonSearchbar,
  withIonLifeCycle
} from '@ionic/react';
import Contact from './Contact';
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import Bexio from "../api/Bexio";

const mapStateToProps = (state :any) => {
   return state.authentication
}



class List extends React.Component<any,any> {

  private bexio : any; 

  constructor(props : any) {
      super(props);
      this.state = {
        contacts : [],
        searchText : '',
      }
  } 

  ionViewWillEnter() {//
     if (this.props.loggedIn) {
        this.bexio = new Bexio(this.props.jwt.access_token);
        this.loadContacts()
     }
  }

  loadContacts() {
     this.bexio.contacts().then((response : any) => {
        this.setState({ contacts : response });
     });     
  }

  render() { 
    if (!this.props.loggedIn) return <Redirect to='/auth' />
    let max = 30;
    let nb = 0;
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/contacts"/>
            </IonButtons>
            <IonTitle>Contacts list</IonTitle>
          </IonToolbar>
          <IonToolbar>
            <IonSearchbar value={this.state.searchText} onIonChange={e => this.setState({ searchText : e.detail.value!})}></IonSearchbar>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
              { this.state.contacts.length < 1 ? 
                Array.from(Array(max).keys()).map((i : any, index : number) => (
                  <IonItem key={index} >
                    <IonSkeletonText animated style={{ width: '100%' }} />
                  </IonItem>
                ))
                :      
                this.state.contacts.map((cts: any) => (                  
                  (cts.name_1.toLowerCase().indexOf(this.state.searchText.toLowerCase()) > -1 ||
                   cts.name_2.toLowerCase().indexOf(this.state.searchText.toLowerCase()) > -1  ?  
                      (nb++, (nb <= max) ? <Contact key={cts.id} {...cts}></Contact> : '') 
                   : '')
                ))
              }
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
};
export default connect(mapStateToProps,null)(withIonLifeCycle(List));