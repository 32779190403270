import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonSkeletonText,
  IonRouterLink,
  withIonLifeCycle,
} from '@ionic/react';
import React from 'react';
import queryString from 'query-string'
import axios from 'axios';
import {loginFailure,loginSuccess, logoutSuccess} from "./Login.slice";
import {useDispatch, useSelector} from 'react-redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import * as microsoftTeams from "@microsoft/teams-js"; 

const config = require('./config');

const mapDispatchToProps = {
   loginSuccess,
};

microsoftTeams.initialize();

const isTeams = navigator.userAgent.indexOf("Teams") !== -1; 

const mapStateToProps = (state :any) => {
   return state.authentication
}

class Login extends React.Component<any,any> {

  constructor(props : any) { 
      super(props)
      this.state = {
        loading : true,
      }
  }

 
  componentWillMount() {
     const values = queryString.parse(this.props.location.search);
     if (!this.props.loggedIn && localStorage.getItem('refresh') != null)  this.refresh();
     else if (values.code != null && this.props.loggedIn == false)         this.callback(values);
     else                                                                  this.setState({ loading : false });
  }

  openLoginWindow = () => {
      let t = this;
      const url = config.authorize+ 
           '?client_id='+config.clientID+ 
           '&client_secret='+config.clientSecret+ 
           '&redirect_uri='+config.redirectURI  +
           '&scope=' + config.scope +
           '&state=test' +
           '&response_type=code';

      microsoftTeams.authentication.authenticate({
        url:  window.location.origin + "/start_auth.html?url="+encodeURIComponent(url),
        width: 980,
        height: 850,
        successCallback: function (values) {
           window.location.reload(false);
        },
        failureCallback: function (reason) {              
           if (!isTeams) window.location.reload(false);
        }
      }); 
  }

  callback (values :any) {
     const url = config.proxy + '&type=formurlencoded&url=' + config.token;
     axios.post(url,queryString.stringify({
       grant_type : 'authorization_code',
       client_id : config.clientID,
       client_secret : config.clientSecret,
       code : values.code,
       redirect_uri : config.redirectURI,
     }),
     {
       headers : {
        'Content-Type': 'application/x-www-form-urlencoded'
       }
     }).then((response) => {
        this.getUserInfo(response.data);
     }).catch((error) => {        
         this.setState({ loading : false });
         console.log(error);
     }).then(() => {})
  }

  refresh () {
      const url = config.proxy + '&type=formurlencoded&url=' + config.token;
      axios.post(url,queryString.stringify({
         grant_type : 'refresh_token',
         client_id : config.clientID,
         client_secret : config.clientSecret,
         refresh_token : localStorage.getItem('refresh'),
         redirect_uri : config.redirectURI,
      }),
      {
         headers : {
          'Content-Type': 'application/x-www-form-urlencoded'
         }
      }).then((response) => {
          this.getUserInfo(response.data);
      }).catch((error) => {
          this.setState({ loading : false });
          console.log(error);
      }).then(() => {})
  }

  getUserInfo(jwt : any) {
     const url = config.user
     const token = jwt.access_token

     axios.defaults.headers.common['Authorization'] = "Bearer "+ token;
     axios.get(url).then((response) => { 
       const email = response.data.sub;
       const url = config.proxy + "&type=json&url=https://api.bexio.com/3.0/users/search";
       axios.post(url,
            [{
              "field": "email",
              "value": email,
              "criteria": "="
            }] 
          ,{
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
           }
       }).then((response) => { 
          let data = {user : response.data[0], jwt : jwt} 
          this.props.loginSuccess(data)
          const values = queryString.parse(this.props.location.search);
          if (values.code != null) microsoftTeams.authentication.notifySuccess();          
          this.setState({ loading : false });       
          //this.props.history.push('/auth', {animate : false})
       })
     });
  }

  render() { 
    return (      
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
               <IonBackButton defaultHref="/auth"/>
            </IonButtons>
            <IonTitle>Auth</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>

          {this.state.loading ? 
              <IonSkeletonText animated style={{ width: '100%',  height : '30px' }} />
            : this.props.loggedIn ?
               <IonCard>
                  <IonCardHeader>
                    <IonCardSubtitle>{this.props.user.email}</IonCardSubtitle>
                    <IonCardTitle>Logged In !</IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent>
                      {this.props.user.firstname } {this.props.user.lastname}
                  </IonCardContent>
               </IonCard>
            :  
               <IonButton onClick={this.openLoginWindow}>Connexion Bexio</IonButton>
            }           
        </IonContent>
      </IonPage>
    );
  }
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter((withIonLifeCycle(Login))));