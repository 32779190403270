import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonItem,
  IonSkeletonText,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonList,
  withIonLifeCycle
} from '@ionic/react';
import Project from './Project';
import React from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import { connect } from 'react-redux';
import Bexio from '../api/Bexio';

const mapStateToProps = (state :any) => {
   return state.authentication
}

class List extends React.Component<any,any> {

  private bexio : any; 

  constructor(props : any) {
      super(props);
      this.state = {
        projects : false,
        searchText : '',
        states : false,
        contacts : false,
      }
  } 

  ionViewWillEnter() {//
     if (this.props.loggedIn) {
       this.bexio = new Bexio(this.props.jwt.access_token);
       this.loadProjects()
       this.loadStates()
       this.loadContact()
     } 
  }

  loadProjects() {
    this.bexio.projects().then((response : any) => {
        this.setState({ projects : response });
    }); 
  }

  loadStates() {
    this.bexio.projectStates().then((response : any) => {
        this.setState({ states : response });
    });     
  }


  loadContact() {
     this.bexio.contacts().then((response : any) => {
        this.setState({ contacts : response });
     });   
  }

  render() { 
    if (!this.props.loggedIn) return <Redirect to='/auth' />
    let max = 30;
    let nb = 0;
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/projects"/>
            </IonButtons>
            <IonTitle>Projects list</IonTitle>
          </IonToolbar>          
          <IonToolbar>
            <IonSearchbar value={this.state.searchText} onIonChange={e => this.setState({ searchText : e.detail.value!})}></IonSearchbar>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
              {       
                !this.state.projects || !this.state.states || !this.state.contacts  ? 
                Array.from(Array(max).keys()).map((i : any, index : number) => (
                  <IonItem key={index}>
                    <IonSkeletonText  animated style={{ width: '100%' }} />
                  </IonItem>
                ))
                :      
                this.state.projects.map((pjs: any) => (
                  (pjs.name.toLowerCase().indexOf(this.state.searchText) > -1 ?
                  (nb++, (nb <= max) ? 
                   (pjs.state = this.state.states.find((x  : any) => x.id === pjs.pr_state_id), 
                    pjs.contact = this.state.contacts.find((x  : any) => x.id === pjs.contact_id),
                    <Project key={pjs.id} {...pjs } ></Project>) : '') : '')
                ))
              }
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
};
export default connect(mapStateToProps,null)(withIonLifeCycle(List));

         