import {createSlice} from '@reduxjs/toolkit'

const initialState = {loggedIn: false, user : {}, jwt : {}};

const LoginSlice = createSlice(
    {
        name: 'authentication',
        initialState: initialState,
        reducers: {
            loginSuccess(state, action : any) {
                localStorage.setItem('refresh', action.payload.jwt.refresh_token);
                return {
                    loggedIn: true,
                    user: action.payload.user,
                    jwt : action.payload.jwt
                };
            },
            loginFailure(state, action : any) {
                return initialState
            },
            logoutSuccess(state, action : any) {
                localStorage.removeItem('refresh');
                return initialState
            },
        },
    });

export const {loginSuccess, loginFailure, logoutSuccess} = LoginSlice.actions;

export default LoginSlice.reducer;