
module.exports = {
  clientID: '28f0816a-05a9-4add-8b3a-4ccf945b0978',
  clientSecret:'Zcy4MA8K0qnXUoZBpzMn9waJxdqRxsbZ_qgHo-1aUP9gqFCYzwcDiCPAaVd2aI02waISg4k5sX_adxHjE5xc1A',
  redirectURI: 'https://bexio.iqon.ch/auth/callback',
//  redirectURI : 'http://localhost:3000/auth/callback',
  authorize : 'https://idp.bexio.com/authorize',
  token : 'https://idp.bexio.com/token',
  scope : 'project_show openid contact_show monitoring_show offline_access',
  user : 'https://idp.bexio.com/userinfo',
  jwk : 'https://idp.bexio.com/jwt',
  proxy : 'https://bexio.iqon.ch/api/cors-proxy.php?mode=native',
  exportURL : 'https://bexio.iqon.ch/api/export.php'
}; 