import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {createLogger} from 'redux-logger';
import React from "react";
import loginReducer from "../auth/Login.slice"

const loggerMiddleware = createLogger();

const appStore = configureStore(
    {
        reducer: {
            authentication: loginReducer,
        },
        middleware: [...getDefaultMiddleware(), loggerMiddleware]
    });

export const storeContext = React.createContext(null);
export default appStore; 