import axios from 'axios';
const config = require('../auth/config');

class Bexio {

	access_token : string;
	proxy   : string = config.proxy; 
	baseUrl : string = "&url=https://api.bexio.com/";
	version : string = "2.0";

	constructor(access_token : any) {
	    this.access_token = access_token;
		axios.defaults.headers.common['Authorization']  = "Bearer "+ this.access_token;
		axios.defaults.headers.common['Accept'] = 'application/json';
		axios.defaults.headers.common['Content-Type'] = 'application/json';
	}	

	/**************** COMPANY ********************/

	async company() {
		const url = this.proxy + this.baseUrl + this.version + "/company_profile";
		const response = await axios.get(url);
		return response.data;
	}

	/**************** CONTACTS ********************/

	async contacts(id : any = "") {
		const url = this.proxy + this.baseUrl + this.version + "/contact/"+ id;
		const response = await axios.get(url);
		return response.data;
	}

	/**************** CLIENT SERVICE ********************/

	async clientServices() {
		const url = this.proxy + this.baseUrl + this.version + "/client_service";
	    const response = await axios.get(url);
	    return response.data;
	}

	/**************** PROJECTS ********************/

	async projects(id : any = "") {
		const url = this.proxy + this.baseUrl + this.version + "/pr_project/"+ id;
	    const response = await axios.get(url);
	    return response.data;
	}

	async projectsByContact(contact_id : number) {
		const url = this.proxy + "&type=json" +   this.baseUrl + this.version + "/pr_project/search";
		const response = await axios.post(url,
		      [{
		        "field": "contact_id",
		        "value": contact_id,
		        "criteria": "="
		      }]		    
			 )
		return response.data;
	}

	async projectStates(id : any = "") {
		const url = this.proxy + this.baseUrl + this.version + "/pr_project_state/"+ id;
	    const response = await axios.get(url)
	    return response.data;
	}

	/**************** TIMESHEETS ********************/

	async timesheets(id : any = "") {
		const url = this.proxy + this.baseUrl + this.version + "/timesheet/"+ id;
	    const response = await axios.get(url)
	    return response.data;
	}

	async timesheetsByProject(id : number) {
	 	const url = this.proxy + "&type=json" +   this.baseUrl + this.version + "/timesheet/search";
	    const response = await axios.post(url,
	          [{
	            "field": "pr_project_id",
	            "value": id,
	            "criteria": "="
	          }] 
	     	)
	    return response.data
	}

	async timesheetStatus(id : any = "") {
		const url = this.proxy + this.baseUrl + this.version + "/timesheet_status/"+ id;
	    const response = await axios.get(url)
	    return response.data;
	}

	/**************** USERS ********************/

	async users(id : any = "") {		
		let url = this.proxy + this.baseUrl  + "3.0/users/" + id;
	    let response = await axios.get(url)

		url = this.proxy + this.baseUrl  + "3.0/fictional_users/" + id;
		let response2 = await axios.get(url)

		if (id != "") {
			if (response.data.error_code == "404") response.data = response2.data;
		} else {
			response.data = response.data.concat(response2.data)
		}

	    return response.data;
	} 


	/*************** EXPORT ********************/
	async project_export(id : number, start : any, end : any, client_service : any, lang : any) {
		let client_service_rqst = "";
		console.log(client_service);
		client_service.map ((service  : any) => {
                client_service_rqst += "&client_service["+service.id+"]="+service.default_price_per_hour;
            });
		const url = config.exportURL +  '?start='+start+'&end='+end+'&id='+id+client_service_rqst+'&lang='+lang;
	    const response = await axios.get(url, { responseType: 'blob' });
	    return new File([response.data], 'export.pdf');      
	}

		
}
export default Bexio